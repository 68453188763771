import { ChevronRightIcon } from "@heroicons/react/outline";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import React from "react";
import EstimatedReadingTime from "~components/blog/EstimatedReadingTime";
import Button from "~components/common/Button";
import CardProjekt from "~components/common/CardProjekt";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import Grid from "~components/common/Grid";
import BlurbSphere from "~components/features/BlurbSphere";
import Layout from "~components/layouts/Layout";
import ServiceExpertsLinks from "~components/linkCollections/ServiceExpertsLinks";
import ServiceLinks from "~components/linkCollections/ServiceLinks";
import About from "~components/sections/landingpages/About";
import H1 from "~components/typography/H1";
import H2 from "~components/typography/H2";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";
import type Blog from "~types/Blog";
import type GatsbyImagePropsMeta from "~types/GatsbyImagePropsMeta";
import type Location from "~types/Location";
import type Projekt from "~types/Projekt";

interface DataProps {
	site: {
		siteMetadata: {
			siteUrl: string,
			contact: {
				street: string,
				plz: string,
				phone: string,
				mail: string,
				city: string,
			},
		},
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta,
		twitter: GatsbyImagePropsMeta,
	};
	header: ImageDataLike;
	letzterBeitrag: {
		nodes: Blog[],
	};
	allProjekte: {
		nodes: Projekt[],
	};
}

export default function Page({ data, location }: { data: DataProps, location: Location }) {
	const headerImage = getImage(data.header);

	return (
		<Layout
			location={location}
			title="Werbeagentur Kassel ▷ Webdesign | SEO | Marketing"
			desc="Die Werbeagentur Kassel Lautenschlager Marketing & Entwicklung bietet online Lösungen von Websites bis hin zu Suchmaschinenoptimierung."
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			schemaLocal
		>
			<Container noPadding className="py-6">
				<Grid cols={2} itemsCenter>
					<div>
						<NumberedSection text="Marketing & Entwicklung" number="01" className="mb-3" />
						<H1 display className="mb-5 font-display">
							Werbeagentur Kassel: Online-Marketing & Entwicklung
						</H1>
						<P className="max-w-lg">
							Online, aber richtig! Die Werbeagentur{" "}
							<Link to="/ueber-uns/" className="underline">
								Lautenschlager Marketing & Entwicklung
							</Link>{" "}
							Kassel bietet Full Service Lösungen von einfachen Websites bis hin zur Suchmaschinenoptimierung. Mit
							intelligentem Design und ausgefeilter Technologie erschaffen wir Websites, die nachhaltig Deine Prozesse
							optimieren und Kunden ohne teure Werbeanzeigen beschaffen.
						</P>
					</div>
					{headerImage ? (
						<div>
							<GatsbyImage
								image={headerImage}
								className="mx-auto"
								alt="Werbeagentur Kassel | Andreas Lautenschlager im Portrait"
								title="Werbeagentur Kassel | Andreas Lautenschlager im Portrait"
							/>
						</div>
					) : null}
				</Grid>
			</Container>

			<Container>
				<h2 className="mb-3 font-display text-xl uppercase md:px-6 md:text-right md:text-3xl">Letzte Projekte</h2>
				<Grid cols={3}>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Individuell" number="02" className="mb-5" />
						<P className="mb-5">
							Jedes Projekt ist so individuell, wie das Business-Modell dahinter und das Spiegeln unsere Websites in
							allen Beriechen wieder. Besuche gerne die Website-Projekte, die wir zuletzt erstellt haben und als
							Werbeagentur Kassel präsentieren dürfen.
						</P>
					</div>
					<div className="bg-pattern col-span-2 grid gap-3 p-3 lg:grid-cols-2">
						{data.allProjekte.nodes.map((projekt) => (
							<CardProjekt
								title={projekt.title}
								image={projekt.remoteImage}
								href={projekt.link}
								tech={projekt.tech}
								key={projekt.title}
							/>
						))}
					</div>
				</Grid>
			</Container>

			<Container>
				<Divider className="mb-4" />
				<NumberedSection text="Konzept und Entwicklung" number="03" className="mb-5" />
				<H2 display className="mb-5 font-display">
					Leistungen
				</H2>
				<Grid cols={3}>
					<div>
						<P className="mb-3">
							Werbeagentur Kassel: Konzeptionierung und Realisierung von Profis. Wir bauen nicht nur Websites, sondern
							möchten eine erfolgreiche Plattform für Dein Business aufbauen. Dazu gehörten ein vernünftiges Auftreten,
							eine blitzschnelle Website, digitale Prozesse und eine funktionierende Vermarktung. Das alles realisieren
							wir als Werbeagentur Kassel durch fachgerechte Kompetenz und richtigen Partnern.
						</P>
						<Button text="Alle Leistungen" to="/leistungen/" white />
					</div>
					<div className="md:col-span-2">
						<ServiceLinks />
					</div>
				</Grid>
			</Container>

			<Container>
				<Divider className="mb-4" />
				<NumberedSection text="Für Experten" number="04" className="mb-5" />
				<H2 display className="mb-5 font-display">
					Expertisen
				</H2>
				<Grid cols={3}>
					<div>
						<P className="mb-3">
							Wir sind auf bestimmte Technologien spezialisiert. Suchst Du einen Experten in diesen Bereichen, stehen
							wir Dir gerne zur Verfügung.
						</P>
						<Button text="Alle Leistungen" to="/leistungen/" white />
					</div>
					<div className="md:col-span-2">
						<ServiceExpertsLinks />
					</div>
				</Grid>
			</Container>
			<Container>
				<Divider className="mb-4" />
				<NumberedSection text="Blog" number="06" className="mb-5" />
				<H2 display className="mb-5 font-display">
					Letzter Blog-Beitrag
				</H2>
				<Grid cols={3}>
					<div>
						<P className="mb-3">
							Wir versuchen als Marketingagentur unsere Kunden ständig auf dem neusten Stand der Dinge zu halten. Lese
							also doch gerne in unseren neusten Blog-Beitrag rein.{" "}
						</P>
						<Button text="Zum Blog" to="/blog/" white />
					</div>
					<div className="md:col-span-2">
						{data.letzterBeitrag.nodes.map((artikel) => {
							const artikelImage = getImage(artikel.remoteImage);
							return (
								<div className="bg-pattern mb-8 p-2" key={artikel._id}>
									<div className="bg-white p-3">
										<Grid cols={2}>
											{artikelImage ? (
												<div>
													<Link to={`/blog/${artikel.titel_slug}/`}>
														<GatsbyImage image={artikelImage} alt={artikel.titel} title={artikel.titel} />
													</Link>
												</div>
											) : null}
											<div className="md:flex md:items-center">
												<div className="flex flex-col justify-between">
													<div className="flex-1">
														{artikel.thema.display && (
															<p className="text-sm font-medium text-gray-700">{artikel.thema.display}</p>
														)}

														<Link to={`/blog/${artikel.titel_slug}/`} className="mt-2 block">
															<h2 className="text-xl font-semibold text-gray-900">{artikel.titel}</h2>
															<p className="mt-3 text-base text-gray-500">{artikel.einleitung}</p>
															<div className="mt-3 flex flex-col text-sm text-gray-500 md:flex-row md:space-x-1">
																<time dateTime={artikel.datum}>{artikel.datum}</time>
																{artikel.text && (
																	<>
																		<span aria-hidden="true" className="hidden md:inline">
																			·
																		</span>
																		<span>
																			<EstimatedReadingTime text={artikel.text} />
																		</span>
																	</>
																)}
															</div>
														</Link>

														<Button text="Weiterlesen" to={`/blog/${artikel.titel_slug}/`} className="mt-3" />
													</div>
												</div>
											</div>
										</Grid>
									</div>
								</div>
							);
						})}
					</div>
				</Grid>
			</Container>

			<Container>
				<div className="mb-8 md:mb-32">
					<Divider className="mb-4" />
					<NumberedSection text="Sprechen hilft" number="07" className="mb-5" />
					<H2 display className="mb-5 font-display">
						Servicekonzept
					</H2>
					<P className="max-w-xl">
						Service und Kommunikation sind essenzielle Bausteine jedes Projekts. Als{" "}
						<strong>Werbeagentur Kassel</strong> bauen wir auf einem Servicekonzept auf, dass ein agiles und planbares
						Framework bietet: SCRUM.
					</P>
				</div>
				<div className="grid grid-cols-1 gap-16 md:grid-cols-4">
					<BlurbSphere
						number="1"
						title="Planung und Strategie"
						text="Gemeinsam besprechen und planen wir mit Dir, wohin die Reise gehen soll."
						svg={<ChevronRightIcon />}
						className="md:mt-32"
					/>
					<BlurbSphere
						number="2"
						title="Konzept und Design"
						text="Wir erstellen ein Konzept und ein grobes Design für deine Webanwendung."
						svg={<ChevronRightIcon />}
						className="md:mt-0"
					/>
					<BlurbSphere
						number="3"
						title="Entwicklung"
						text="Wir entwickeln Dein Projekt im 2-Wochen Rhythmus und liefern so regelmäßig Ergebnisse."
						svg={<ChevronRightIcon />}
						className="md:mt-48"
					/>
					<BlurbSphere
						number="4"
						title="Analyse"
						text="Sobald Dein Projekt online ist, beobachten wir dieses und geben Empfehlungen für die weitere Vorgehensweise."
						svg={<ChevronRightIcon />}
						className="md:mt-16"
					/>
				</div>
			</Container>

			<Container id="konzept">
				<Grid cols={2} className="mb-8 md:mb-32">
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Echte Lösungen" number="08" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Entwicklungslösungen bei der Werbeagentur Kassel
						</H2>
						<P className="mb-3">
							Webdesign und kreative Lösungen sind als unser Tagesgeschäft. Responsive Design und blitzschnelle Websites
							sind bei uns die Regel. Also wie jede andere Werbeagentur in Kassel?
						</P>
						<P>
							Nein! Wir bieten professionelles Webdesign für kleine uns mittelständische Unternehmen. Diese Unternehmen
							sind ständig im Wandel und so sollte auch die Website sein. Neben der Website gehören viele weitere
							Bereiche zu einer erfolgreichen Website. Wir programmieren in den meisten fällen extrem schnelle und
							anpassbare{" "}
							<Link to="/blog/was-ist-jamstack-die-neue-architektur-fuer-websites/" className="underline">
								JAMStack
							</Link>{" "}
							Webseiten. Dies ist die Grundbasis für blitzschnelle und anpassbare Webseiten. Die Programmierung von
							skalierbaren Websites gehören an unsere Tagesordnung und begleiten Dich bei der digitalen Transformation.
							Echte digitale Lösungen schaffen wir durch Anbindung von Schnittstellen und Tools. Kurz gesagt: Wir
							schaffen Mehrwert und Designs, die Dich und Deine Zielgruppe begeistern werden.
						</P>
					</div>

					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Online-Marketing" number="09" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Marketinglösungen
						</H2>
						<P className="mb-3">
							Unsere Erfahrung hat gezeigt, dass gerade kleine Unternehmen eine Marketingstrategie von einer benötigen.
							Der einfache Flyer hat zwar seine Daseinsberechtigung, kann aber mit den modernen Methoden nicht
							mithalten. Deswegen bieten wir als Werbeagentur in Kassel langfristige Strategien wie lokales{" "}
							<Link to="/leistungen/seo-agentur-kassel/" className="underline">
								SEO für Kassel
							</Link>{" "}
							an. Die Kunden sind im Netz unterwegs, das brauche ich Dir nicht zu sagen. Damit Du also jetzt auch
							gefunden wirst, bauen wir eine erfolgreiche SEO Strategie auf. So wirst Du bei Google auf Seite eins
							landen. Und nein, nur weil Du Deinen eigenen Namen googelst und auf der ersten Seite landest, heißt das
							nicht, dass die Kunden Dich auch im Internet finden.
						</P>
						<P className="mb-3">
							Eine erfolgreiche Kampagne baut darauf auf, dass Kunden mit ganz generischen Suchwörtern wie &quot;Friseur
							Kassel&quot; oder &quot;Handwerker in der Nähe&quot; Dich finden. Überprüfe gerne selbst, was Deine Kunden
							suchen könnten und ob Du schon auf der ersten Seite auftauchst!
						</P>
						<P>
							Aber auch digitale Werbung findest Du bei uns. Unsere Erfahrung zeigt, dass eine erfolgreiche Kombination
							aus bezahlter Werbung als Start für Deine Website der richtige Booster ist, bis die SEO-Maßnahmen greifen.
						</P>
					</div>
				</Grid>
			</Container>
			<About />
		</Layout>
	);
}

export const query = graphql`
	{
		header: file(relativePath: { eq: "pages/index/lautenschlager-marketing-entwicklung_header.png" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		allProjekte(limit: 2, sort: { order: DESC, fields: _created }) {
			nodes {
				title
				remoteImage {
					childImageSharp {
						gatsbyImageData(width: 711)
					}
				}
				link
				tech {
					value
				}
			}
		}

		letzterBeitrag: allBlog(filter: { public: { eq: true } }, sort: { fields: datum, order: DESC }, limit: 1) {
			nodes {
				_id
				titel
				titel_slug
				remoteImage {
					childImageSharp {
						gatsbyImageData
					}
				}
				einleitung
				datum
				text
				thema {
					display
					link
				}
			}
		}

		seoImage: file(relativePath: { eq: "pages/index/lautenschlager-marketing-entwicklung_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
				contact {
					street
					plz
					phone
					mail
					city
				}
			}
		}
	}
`;
